import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./banner.css";
import { Context } from "../context/index";
import { bannerdescription } from "../context/bannerpage";

const bannerCard = (props) => {
  const [mpVideo, setMpVideo] = React.useState(0);
  const maxMpVideo = 3;
  const { state, dispatch } = React.useContext(Context);
  const { language } = state;

  let mainPageVideoCuts = [];
  for (let i = 1; i <= maxMpVideo; i++) {
    mainPageVideoCuts.push(process.env.PUBLIC_URL + `/videos/main${i}.mp4`);
  }

  async function Switch(plus = true) {
    if (plus) {
      setMpVideo((prev) => (prev >= maxMpVideo - 1 ? 0 : prev + 1));
    } else {
      setMpVideo((prev) => (prev <= 0 ? maxMpVideo - 1 : prev - 1));
    }
  }

  return (
    <>
      {/*<CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image=""
          />*/}
      <div style={{ width: "100%" }} className="App-mainPageVideoTop">
        <video
          key={mpVideo}
          // onMouseEnter={() => setPlayBtn(true)}
          // onMouseLeave={() => setPlayBtn(false)}
          width="100%"
          height="100%"
          autoPlay
          muted
          controls={false}
          onEnded={() => Switch(true)}
          src={mainPageVideoCuts[mpVideo]}
          style={{ objectFit: "cover" }}
          // ref={videoRef}
        >
          Video tag is not supported by your browser.
        </video>

        <div className="video-poster-cover-bottom" />
      </div>
      <div
        style={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -40%)",
          zIndex: 9,
        }}
      >
        <Typography
          gutterBottom
          variant="h1"
          component="div"
          align="center"
          sx={{
            fontFamily: "Helvetica Neue",
            fontWeight: 700,
            letterSpacing: ".3rem",
            textDecoration: "none",
            //background: `rgb(255, 255, 255)`
            color: "white",
          }}
        >
            {/*<img
                src="./springfilmstudiologo.png"
                alt="Spring Film Studios"
                loading="lazy"
                style={{
                    width: "100%",
                    objectFit: "contain"
                }}
            />*/}
        </Typography>
        {/*<Typography 
            variant="h1" 
            align="center"
            sx={{
                fontFamily: "Helvetica Neue",
                fontWeight: 700,
                letterSpacing: "".3rem",
                textDecoration: "none",
                //background: `rgb(255, 255, 255)`
                color: "white",
            }}>
            Films Studios
        </Typography>*/}

        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            align="center"
            sx={{
              fontFamily: "Helvetica Neue",
              //fontWeight: 700,
              //letterSpacing: '.3rem',
              color: "white",
              textDecoration: "none",
              //background: `rgb(255, 255, 255)`
            }}
          >
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            {bannerdescription.centrallondon.title[language]}
          </Typography>
          <Typography gutterBottom variant="h5" component="div" align="center">
            <Button
              sx={{
                alignItems: "center",
                color: "white",
                background: `rgb(105, 35, 35)`,
                padding: "5px 20px",
                fontFamily: "Helvetica Neue"
              }}
              size="medium"
              onClick={() =>
                (window.location.href = "https://xpring.co.uk/contact?type=0")
              }
            >
              {bannerdescription.getintouch.title[language]}
            </Button>
          </Typography>
        </CardContent>
      </div>
    </>
  );
};

export default bannerCard;
