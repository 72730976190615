export const bannerdescription = {
    centrallondon: {
        title: {
            caption: {
                EN: "",
                GB: ""
            },
            EN: "Central London's Adult Film & Game Studios",
            GB: "伦敦市中心的成人电影和游戏工作室"
        }
    },
    getintouch: {
        title: {
            caption: {
                EN: "",
                GB: ""
            },
            EN: "GET IN TOUCH!",
            GB: "取得联系！"
        }
    }
}