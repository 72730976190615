import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Appbar from "./Appbar";
import Footer from "./Footer";
import BannerCard from "./Banner";
import ContentCard from "./Contentcard";
import { Context } from "../context/index";
import { springdescription } from "../context/springhomepage";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  background: `rgb(0, 0, 0)`,
  color: "black",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  background: `rgb(0, 0, 0)`,
  color: "black",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { state, dispatch } = React.useContext(Context);
  const { language } = state;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div
      // sx={{
      //   display: "flex",
      //   background: `rgb(0, 0, 0)`,
      //   color: "black",
      // }}
      style={{ width: "100%", padding: "0px" }}
    >
      <Appbar
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        drawerWidth={drawerWidth}
      ></Appbar>
      <BannerCard />
      <div
        style={{
          width: "100%",
          padding: "0px",
          maxWidth: "1280px",
          textAlign: "center",
          margin: "0 auto",
        }}
        // component="main"
        // sx={{
        //   flexGrow: 1,
        //   // p: 8,
        //   background: `rgb(0, 0, 0)`,
        //   color: "black",
        // }}
      >
        <DrawerHeader />
        <Typography
          //variant="h4"
          align="center"
          sx={{
            fontFamily: "Helvetica Neue",
            fontWeight: 700,
            fontSize: "30px",
            letterSpacing: ".3rem",
            textDecoration: "none",
            color: "white",
          }}
        >
          {springdescription.welcometo.title[language]}
        </Typography>
        <ContentCard
          title={springdescription.experience.title.caption[language]}
          body={
            <div>
              <p>{springdescription.experience.title[language]}</p>
            </div>
          }
        />
        <ContentCard
          title={springdescription.films.title.caption[language]}
          body={
            <div>
              <p>{springdescription.films.title[language + "1"]}</p>
              <br />
              <p>{springdescription.films.title[language + "2"]}</p>
            </div>
          }
        />
        <ContentCard
          title={springdescription.partner.title.caption[language]}
          body={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <img
                src="./資產184x.png"
                style={{
                  fontFamily: "Helvetica Neue",
                  color: "white",
                  width: "10%",
                  objectFit: "contain",
                }}
              />
              <img
                src="./MASOOBU_w.png"
                style={{
                  fontFamily: "Helvetica Neue",
                  color: "white",
                  width: "10%",
                  objectFit: "contain",
                }}
              />
              <img
                src="./果冻w.png"
                style={{
                  fontFamily: "Helvetica Neue",
                  color: "white",
                  width: "10%",
                  objectFit: "contain",
                }}
              />
              <img
                src="./蜜桃logo.jpg"
                style={{
                  fontFamily: "Helvetica Neue",
                  color: "white",
                  width: "10%",
                  objectFit: "contain",
                }}
              />
              <img
                src="./透明背景logo.png"
                style={{
                  fontFamily: "Helvetica Neue",
                  color: "white",
                  width: "10%",
                  objectFit: "contain",
                }}
              />
              <img
                src="./ysw.png"
                style={{
                  fontFamily: "Helvetica Neue",
                  color: "white",
                  width: "10%",
                  objectFit: "contain",
                }}
              />
              <img
                src="./jdblack.png"
                style={{
                  fontFamily: "Helvetica Neue",
                  color: "white",
                  width: "10%",
                  objectFit: "contain",
                }}
              />
            </div>
          }
        />
        <ContentCard
          title={springdescription.contactus.title.caption[language]}
          body={
            <div>
              <img
                onClick={() =>
                  (window.location.href = "https://xpring.co.uk/contact?type=0")
                }
                src="./contact.png"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
            </div>
          }
        />
        <br />
        <Footer />
      </div>
    </div>
  );
}
