import { initialState } from "./index";

export function reducer(state, action) {
  switch (action.type) {
    case "RESET_STATE":
      return initialState;
    case "isOpenMenu":
      return {
        ...state,
        isOpenMenu: action.payload,
      };
    case "isOpen":
      return {
        ...state,
        isOpen: action.payload,
      };
    case "account":
      return {
        ...state,
        account: action.payload,
      };
    case "over18":
      return {
        ...state,
        over18: action.payload,
      };
    case "language":
      return {
        ...state,
        language: action.payload,
      };
    case "currentLoginAccount":
      return {
        ...state,
        currentLoginAccount: action.payload,
      };
    case "currentLoginID":
      return {
        ...state,
        currentLoginID: action.payload,
      };
    case "currentLoginPermission":
      return {
        ...state,
        currentLoginPermission: action.payload,
      };
    case "currentPurchased":
      return {
        ...state,
        currentPurchased: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
