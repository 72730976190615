export const springdescription = {
    welcometo: {
        title: {
            caption: {
                EN: "",
                GB: ""
            },
            EN: "Welcome to Spring Film Studios",
            GB: "欢迎来到 Spring 电影工作室"
        }
    },
    experience: {
        title: {
            caption: {
                EN: "Our Experience",
                GB: "我们的经验"
            },
            EN: "Spring Film Studios is a dynamic video production studio specialising in high-quality, artistic adult content. We work with Europe and Asia's top adult performers and bring cinematic visions of sex to life. Our services go beyond traditional filmmaking, offering interactive game content, custom social media clips, short media series and cutting-edge VR video production.",
            GB: "Spring 电影工作室是一家充满活力的视频制作工作室，专门制作高品质、艺术性的成人内容。我们与欧洲和亚洲的顶级成人演员合作，将电影般的性爱场景带入现实。我们的服务超越了传统的电影制作，提供互动游戏内容、定制社交媒体剪辑、短片系列和尖端的 VR 视频制作。"
        }
    },
    films: {
        title: {
            caption: {
                EN: "Our Films",
                GB: "我们的电影"
            },
            EN1: "Over the past two years, we've produced over 100 films across various styles and categories, from short scenes to full movie-style stories and series. Our portfolio showcases diverse sexual expression, known for its artistry and innovation. Our exclusive adult content will be constantly released on xpring.co.uk.",
            GB1: "在过去的两年中，我们制作了 100 多部电影，涵盖各种风格和类别，从短片到完整的电影风格故事和系列。我们的作品展示了多样化的性表达，以其艺术性和创新性而闻名。我们的独家成人内容将不断在 xpring.co.uk 上发布。",
            EN2: "We’ve also created interactive game content with 150+ scenes and multiple choices, providing personalised experiences. We’ve worked with short media platforms and produced 200+ episodes. With social media content averaging 200k views per post, we consistently captivate and engage diverse audiences. At Spring Film Studios, we push creative boundaries, delivering visually stunning and emotionally compelling adult content across multiple formats.",
            GB2: "我们还创建了具有 150 多个场景和多种选择的互动游戏内容，提供个性化体验。我们与短媒体平台合作，制作了 200 多集。凭借社交媒体内容，我们每篇帖子的平均浏览量达到 20 万次，始终吸引和吸引不同的观众。在 Spring Film Studios，我们突破创意界限，通过多种格式提供视觉震撼、情感引人入胜的成人内容。"
        }
    },
    partner: {
        title: {
            caption: {
                EN: "Partner",
                GB: "伙伴"
            },
            EN: "TBC",
            GB: "待定"
        }
    },
    contactus: {
        title: {
            caption: {
                EN: "Contact Us",
                GB: "联系我们"
            },
            EN: "",
            GB: ""
        }
    },
}