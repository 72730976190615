/*
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from "@mui/material/Badge";
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from "@mui/icons-material/Notifications";
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
*/

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { Context } from "../context/index";
import { appdescription } from "../context/appbarpage";

const pages = ["Studios", "Experience", "Films", "Clients", "Contact Us"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const appheadbar = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { state, dispatch } = React.useContext(Context);
  const { language } = state;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLanguage = () => {
    dispatch({
      type: "language",
      payload: language == "EN" ? "GB" : "EN",
    });
  }

  return (
    <AppBar
      position="fixed"
      drawerwidth={props.drawerWidth}
      sx={{
        background: `rgb(0, 0, 0,0.4)`,
        backdropFilter: "blur(3px)",
        padding: "0 1%",
        // background: "transparent",
        //boxShadow: 'none'
      }}
    >
      {/*<Container maxWidth="xl">*/}
        <Typography sx={{ 
          flexGrow: 0,
          fontFamily: 'Helvetica Neue',
          color: `rgb(255, 255, 255)`,
          width: "100%",
          fontWeight: 400,
          fontSize: "30px",
          textAlign:"center"
          //objectFit: "contain"
        }}>
            {appdescription.websiteconstruction.title[language]}
        </Typography>
      <Toolbar
        disableGutters
        style={{ maxWidth: "1280px", margin: "0  auto", width: "100%" }}
      >
        {/*<AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />*/}
        <Typography
          variant="h4"
          noWrap
          component="a"
          href="#app-bar-with-responsive-menu"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "Helvetica Neue",
            //fontWeight: 700,
            //letterSpacing: '.3rem',
            //color: 'inherit',
            textDecoration: "none",
          }}
        >
          {/*<span style={{color: `rgb(105, 35, 35)`}}>S</span>
            <span style={{color: `rgb(255, 255, 255)`}}>pring Film Studios</span>*/}
            <img
              src="./springfilmstudiologo.png"
              alt="Spring Film Studios"
              loading="lazy"
              style={{
                width: "40%",
                objectFit: "contain"
              }}
            />
          {/*<span style="color: rgb(105, 35, 35);">X</span>
            <span class="cls-14New" style="color: rgb(255, 255, 255);">PRING</span>*/}
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                <Typography sx={{ textAlign: "center" }}>{page}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {/*<AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />*/}
        <Typography
          variant="h4"
          noWrap
          component="a"
          href="#app-bar-with-responsive-menu"
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            fontFamily: "Helvetica Neue",
            //fontWeight: 700,
            //letterSpacing: '.3rem',
            //color: 'inherit',
            textDecoration: "none",
          }}
        >
          {/*<span style={{color: `rgb(105, 35, 35)`}}>S</span>
            <span style={{color: `rgb(255, 255, 255)`}}>pring Film Studios</span>*/}
            <img
              src="./springfilmstudiologo.png"
              alt="Spring Film Studios"
              loading="lazy"
              style={{
                width: "30%",
                objectFit: "contain"
              }}
            />
        </Typography>
        {/*<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
              ))
          </Box>*/}
          <Box sx={{ 
              flexGrow: 0,
              fontFamily: 'Helvetica Neue',
              color: `rgb(255, 255, 255)`,
              width: "5%",
              objectFit: "contain"
           }}>
            <IconButton aria-label="lang" size="large" onClick={handleLanguage}>
              <LanguageOutlinedIcon fontSize="inherit" sx={{ color: "#FFF"}} />
            </IconButton>
          </Box>
          <Box sx={{ 
              flexGrow: 0,
              fontFamily: 'Helvetica Neue',
              color: `rgb(255, 255, 255)`,
              width: "25%",
              objectFit: "contain"
           }}>
            {appdescription.watchfilmat.title[language]}
          </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Button
            key={0}
            onClick={() => (window.location.href = "https://xpring.co.uk")}
            sx={{ my: 2, color: "white", display: "block" }}
          >
            <Typography
              variant="h4"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" }, fontFamily: "Helvetica Neue" }}
            >
              <span style={{
                    color: `rgb(105, 35, 35)`,
                    fontFamily: 'Helvetica Neue'
                  }}> X</span>
                  <span style={{
                    color: `rgb(255, 255, 255)`,
                    fontFamily: 'Helvetica Neue'
                  }}>pring</span>
            </Typography>
          </Button>
          {/*<Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>*/}
        </Box>
      </Toolbar>
      {/*</Container>*/}
    </AppBar>
  );
};

export default appheadbar;
