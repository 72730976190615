export const appdescription = {
    watchfilmat: {
        title: {
            caption: {
                EN: "",
                GB: ""
            },
            EN: "Watch our films at",
            GB: "观看我们的电影在"
        }
    },
    websiteconstruction: {
        title: {
            caption: {
                EN: "",
                GB: ""
            },
            EN: "Website Under Construction",
            GB: "网站正在建设中"
        }
    }
}