import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider as ContextProvider } from "./context/index";

//import App from "./App";
import Springhome from "./Components/Springhome";
import { StyledEngineProvider } from "@mui/material/styles";
import { GlobalStyles } from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

const root = createRoot(document.querySelector("#root"));
root.render(
  <StrictMode>
    <ContextProvider>
      <Router>
        <StyledEngineProvider injectFirst>
          <GlobalStyles styles={{ body: { padding: 0, margin: 0 } }} />
          <Springhome />
        </StyledEngineProvider>
      </Router>
    </ContextProvider>
  </StrictMode>
);
