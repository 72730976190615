import * as React from 'react';
import { Box, Grid, Link, Typography, Container, IconButton, Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Context } from "../context/index";
import { footerdescription } from "../context/footerpage";

// Replace these with your own social media URLs
const socialMediaLinks = {
  facebook: '#',
  twitter: '#',
  instagram: '#',
};

const Footer = () => {
  const { state, dispatch } = React.useContext(Context);
  const { language } = state;
  return (
    <Box
      sx={{
        //bgcolor: 'background.paper',
        //color: 'text.secondary',
        bgcolor: 'black',
        color: 'black',
        py: 3,
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Container maxWidth={false} >
        {/*<Grid container spacing={2} justifyContent="center">*/}
          {/*<Grid item xs={12} sm={6} md={3}>*/}
            
            {/*<Typography variant="h4" gutterBottom sx={{
              fontFamily: "Helvetica Neue",

            }}>
              <span style={{color:  `rgb(105, 35, 35)`}}>S</span>
              <span style={{color: `rgb(255, 255, 255)`}}>pring Film Studios</span>
              
            </Typography> */}
          {/*<Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="white" gutterBottom sx={{
              fontFamily: "Helvetica Neue",
              fontSize: "18px"
            }}>
              {footerdescription.product[language]}
            </Typography>
            <Link href="#" color="rgb(255, 255, 255)" display="block" sx={{
              fontFamily: "Helvetica Neue"
            }}>
              {footerdescription.product.feature[language]}
            </Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block" sx={{
              fontFamily: "Helvetica Neue"
            }}>
              {footerdescription.product.integrations[language]}
            </Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block" sx={{
              fontFamily: "Helvetica Neue"
            }}>
              {footerdescription.product.pricing[language]}
            </Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block" sx={{
              fontFamily: "Helvetica Neue"
            }}>
              {footerdescription.product.faq[language]}
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="white" gutterBottom sx={{
              fontFamily: "Helvetica Neue",
              fontSize: "18px"
            }}>
              {footerdescription.company[language]}
            </Typography>
            <Link href="#" color="rgb(255, 255, 255)" display="block" sx={{
              fontFamily: "Helvetica Neue"
            }}>
              {footerdescription.company.aboutus[language]}
            </Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block" sx={{
              fontFamily: "Helvetica Neue"
            }}>
              {footerdescription.company.careers[language]}
            </Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block" sx={{
              fontFamily: "Helvetica Neue"
            }}>
              {footerdescription.company.privacypolicy[language]}
            </Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block" sx={{
              fontFamily: "Helvetica Neue"
            }}>
              {footerdescription.company.termsservice[language]}
            </Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block" sx={{
              fontFamily: "Helvetica Neue"
            }}>
              {footerdescription.company.contactus[language]}
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="white" gutterBottom sx={{
              fontFamily: "Helvetica Neue",
              fontSize: "18px"
            }}>
              {footerdescription.ourstory[language]}
            </Typography>
            <Link href="#" color="rgb(255, 255, 255)" display="block" sx={{
              fontFamily: "Helvetica Neue"
            }}>
              {footerdescription.ourstory.experience[language]}
            </Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block" sx={{
              fontFamily: "Helvetica Neue"
            }}>
              {footerdescription.ourstory.films[language]}
            </Link>
            <Link href="#" color="rgb(255, 255, 255)" display="block" sx={{
              fontFamily: "Helvetica Neue"
            }}>
              {footerdescription.ourstory.partner[language]}
            </Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>*/}
            {/*<Typography variant="subtitle1" color="white" gutterBottom sx={{
              fontFamily: "Helvetica Neue",
              fontSize: "18px"
            }}>
              {footerdescription.socialmedia[language]}
            </Typography>*/}
            <Typography variant="body2" color="white" align="center" sx={{ 
              pt: 4,
              fontFamily: "Helvetica Neue"
            }}>
              <img
                src="./springfilmstudiologo.png"
                alt="Spring Film Studios"
                loading="lazy"
                style={{
                  width: "40%",
                  objectFit: "contain"
                }}
              />
            </Typography>
            <Typography variant="body2" color="white" align="center" sx={{ 
              pt: 4,
              fontFamily: "Helvetica Neue"
            }}>
              <IconButton aria-label="Twitter" color="white" component="a" href={socialMediaLinks.twitter}>
                <XIcon sx={{
                  fontFamily: "Helvetica Neue",
                  color: "white",
                  fontWeight: 300,
                  fontSize: 30
                }}/>
              </IconButton>
              <IconButton aria-label="Instagram" color="white" component="a" href={socialMediaLinks.instagram}>
                <InstagramIcon sx={{
                  fontFamily: "Helvetica Neue",
                  color: "white",
                  fontWeight: 300,
                  fontSize: 30
                }}/>
              </IconButton>
              <Button
                key={0}
                onClick={() => (window.location.href = "https://xpring.co.uk")}
              >
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: "none", sm: "block" }, fontFamily: "Helvetica Neue" }}
                >
                  <span style={{
                    color: `rgb(105, 35, 35)`,
                    fontFamily: 'Helvetica Neue'
                  }}> X</span>
                  <span style={{
                    color: `rgb(255, 255, 255)`,
                    fontFamily: 'Helvetica Neue'
                  }}>pring</span>
                </Typography>
              </Button>
            </Typography>
        {/*/</Grid>*/}
        <Typography variant="body2" color="white" align="center" sx={{ 
          pt: 4,
          fontFamily: "Helvetica Neue"
         }}>
          © 2024 Xpring co. {footerdescription.right[language]}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;