export const footerdescription = {
    product: {
        EN: "PRODUCT",
        GB: "产品",
        feature: {
            EN: "Feature",
            GB: "功能"
        },
        integrations: {
            EN: "Integrations",
            GB: "集成"
        },
        pricing: {
            EN: "Pricing",
            GB: "价格"
        },
        faq: {
            EN: "FAQ",
            GB: "常问问题"
        }
    },
    company: {
        EN: "COMPANY",
        GB: "公司",
        aboutus: {
            EN: "About Us",
            GB: "关于我们"
        },
        careers: {
            EN: "Careers",
            GB: "职业"
        },
        privacypolicy: {
            EN: "Privacy Policy",
            GB: "隐私政策"
        },
        termsservice: {
            EN: "Terms of Service",
            GB: "服务条款"
        },
        contactus: {
            EN: "Contact Us",
            GB: "联系我们"
        }
    },
    ourstory: {
        EN: "OUR STORY",
        GB: "我们的故事",
        experience: {
            EN: "Experience",
            GB: "经验"
        },
        films: {
            EN: "Films",
            GB: "电影"
        },
        partner: {
            EN: "Partners",
            GB: "伙伴"
        }
    },
    socialmedia: {
        EN: "SOCIAL MEDIA",
        GB: "社交媒体"
    },
    right: {
        EN: "All rights reserved.",
        GB: "版权所有"
    }
}