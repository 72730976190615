import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";


const contentCard = (props) => {
    return (
        <Card
          sx={{
            background: `rgb(0, 0, 0)` 
          }}
        >
          {/*<CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image=""
          />*/}
          <CardContent>
            <Typography 
                gutterBottom 
                component="div"
                align="center"
            >
                <br /> 
            </Typography>
            <Typography 
                gutterBottom 
                variant="h4" 
                align="center"
                sx={{
                    fontFamily: 'Helvetica Neue',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'white',
                    textDecoration: 'none',
                }}>
                {props.title}
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="body2" 
              align="center" 
              sx={{ 
                color: 'white',
                fontFamily: "Helvetica Neue",
                fontSize: "20px",
                fontWeight: "300"
              }} 
              //dangerouslySetInnerHTML={{ __html: props.body }}
            >
              {props.body}
            </Typography>
          </CardContent>
          <CardActions>
            
          </CardActions>
        </Card>
    );
}

export default contentCard;
